import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Component/navbar";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Faq from "./pages/faq";
import SimpleSlider from "./Component/SectionCarousel";
import NavbarCorparate from "./Component/nabarcorparate";
import Carousel from "./Component/carousel";
import tyles from "./App.css";
import rnd from "./images/Akumes.jpg"
import image2 from "./images/Team2BP.jpg";
import image1 from "./images/Akumes.jpg";
import image3 from "./images/Conferance_Bangalore_2021_Oct.jpeg";
import image4 from "./images/Conference2_2021_oct.jpeg";
import image5 from "./images/Conferenace3_Bangalore_2021_Oct.jpeg";
import image6 from "./images/Bioplasmidian_baby_OxySafe.jpg";
import image7 from "./images/Unique_products_Calcimed_P.jpg"; 
import image8 from "./images/Unique_products_Calcimed_P.jpg"; 
import product1 from './images/products/4.jpg';
import product2 from './images/products/67.jpg';
import product3 from './images/products/18.jpg';
import product4 from './images/products/23.jpg';
import product5 from './images/products/1920.jpg';
import product6 from './images/products/21.jpg';
import product7 from './images/products/22.jpg';
import product8 from './images/products/24.jpg';
import product9 from './images/products/25.jpg';
import product10 from './images/products/26.jpg';
import product11 from './images/products/27.jpg';
import product12 from './images/products/28.jpg';
import product13 from './images/products/29.jpg';
import product14 from './images/products/30.jpg';
import product15 from './images/products/32.jpg';
import product16 from './images/products/31.jpg';
import product17 from './images/products/34.jpg';
import product18 from './images/products/36.jpg';
import product19 from './images/products/37.jpg';
import product20 from './images/products/38.jpg';
import product21 from './images/products/39.jpg';
import product22 from './images/products/40.jpg';
import product23 from './images/products/41.jpg';
import product24 from './images/products/42.jpg';
import product25 from './images/products/43.jpg';
import product26 from './images/products/44.jpg';
import product27 from './images/products/45.jpg';
import product28 from './images/products/46.jpg';
import officePhoto from './images/Office/office2.jpg';

import aboutUS from "./images/AboutUs_BP.jpg"
import logo from "./images/BPLogo.jpg"
import conference1 from './images/Conference/1.jpg';
import conference2 from './images/Conference/2.JPG';
import conference3 from './images/Conference/3.jpg';
import conference4 from './images/Conference/4.jpg';
import conference5 from './images/Conference/5.jpeg';
import conference6 from './images/Conference/6.JPG';

function App() {
  return (  
    <div>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between">

          <div className="logo">
            <a href="index.html"><img src={logo} alt="" className="img-fluid" /></a>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
            <li className="dropdown"><a href="#"><span>CORPORATE</span> </a>
                <ul>
                  <li><a href="#">Born to Succeed</a></li>
                  <li><a href="#">Milestones</a></li>
                  <li><a href="#">Mission & Vision</a></li>
                  <li><a href="#">Awards & Acolades</a></li>
                  <li><a href="#">Know More about Our Group</a></li>
           
                </ul>
              </li>
              <li className="dropdown"><a href="#"><span>PEOPLE</span> </a>
                <ul>
                  <li><a href="#">Board of Directors</a></li>
                  <li><a href="#">Message from CEO</a></li>
                  <li><a href="#">Learning and Development</a></li>
                  <li><a href="#">Anual Return</a></li>
                </ul>
              </li>  
              <li className="dropdown"><a href="#"><span>MANUFACTURING AND QUALITY</span> </a>
                <ul>
                  <li><a href="#">Manufacturing</a></li>
                  <li><a href="#">Quality</a></li>
                </ul>
              </li>
              
              <li className="dropdown"><a href="#"><span>R & D</span> </a>
                
              </li>  
              <li className="dropdown"><a href="#"><span>PRODUCTS</span> </a>
                <ul>
                  <li><a href="#">For Patients</a></li>
                  <li><a href="#">Medical Practitioners</a></li>
                  <li><a href="#">NLEM Prices</a></li>

                </ul>
              </li>
              <li className="dropdown"><a href="#"><span>NEWSROOM</span> </a>
                <ul>
                  <li><a href="#">New Launches</a></li>
                  <li><a href="#">Events</a></li>
                </ul>
              </li>
              <li className="dropdown"><a href="#"><span>EMPLOYEES</span> </a>
                <ul>
                  <li><a href="#">New Launches</a></li>
                  <li><a href="#">Events</a></li>
                </ul>
              </li>
         </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>

        </div>
      </header>
      <section id="hero">
        <div className="hero-container">
        <div id="carousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
        <img src={image1} className="img-fluid" alt="..." />
    
          
        </div>
        <div className="carousel-item">
        <img src={image3} className="img-fluid" alt="..." />
          
          
        </div>
        <div className="carousel-item">
        <img src={image2} className="img-fluid " alt="..." />
          
        </div>
        <div className="carousel-item">
        <img src={image4} className="img-fluid " alt="..." />
          
        </div>
        <div className="carousel-item">
        <img src={image5} className="img-fluid " alt="..." />
          
        </div>
        <div className="carousel-item">
        <img src={image6} className="img-fluid " alt="..." />
          
        </div>
        <div className="carousel-item">
        <img src={image7} className="img-fluid " alt="..." />
          
        </div>
      </div>


    </div>
        </div>
      </section>

      <main id="main">

<div id="about" className="about-area area-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-12 col-sm-12 col-xs-12">
        <div className="section-headline text-center">
          <h2>About BioPlasmid HealthCare</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 col-sm-12 col-xs-12">
        <div className="well-left">
          <div className="single-well">
            <a href="#">
              <img src={aboutUS} alt=""/>
            </a>
          </div>
        </div>
      </div>

   
    </div>
  </div>
</div>

<div id="services" className="services-area area-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-12 col-sm-12 col-xs-12">
        <div className="section-headline services-head text-center">
          <h2>Our Products</h2>
        </div>
      </div>
    </div>
    <div className="col-md-12 col-sm-12 col-xs-12 prdHeading">

      <h3>Anti Microbials Range of Products & Injectable </h3>
    <hr></hr>
</div>
    <div className="row text-center">
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="about-move">
          <div className="services-details">
            <div className="single-services">
              <img src={product1}></img>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product2}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product3}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product4}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product5}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product6}></img>
    
            </div>
          </div>
        </div>
      </div>
</div>
<div className="row">
<div className="col-md-12 col-sm-12 col-xs-12 prdHeading">
<h3>Anti Fungal and Corticosteroid Range of Products </h3>
<hr></hr>

</div>
    </div>
  
<div className="row text-center">
   
      <div className="col-md-4 col-sm-4 col-xs-12">
      
        <div className="about-move">
          <div className="services-details">
            <div className="single-services">
              <img src={product7}></img>
            </div>
          </div>
        </div>
      </div>
     
    </div>
    <div className="col-md-12 col-sm-12 col-xs-12 prdHeading">

    <h3>Antihistamines Range of Products </h3>
    <hr></hr>

</div>
    <div className="row text-center">
   
   <div className="col-md-4 col-sm-4 col-xs-12">
   
     <div className="about-move">
       <div className="services-details">
         <div className="single-services">
           <img src={product8}></img>
         </div>
       </div>
     </div>
   </div>
  
 </div>
 <div className="col-md-12 col-sm-12 col-xs-12 prdHeading">

 <h3>Nutritional Range of Products </h3>
 <hr></hr>

 </div>
    <div className="row text-center">
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="about-move">
          <div className="services-details">
            <div className="single-services">
              <img src={product9}></img>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product10}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product11}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product12}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product13}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product14}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product16}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product15}></img>
    
            </div>
          </div>
        </div>
      </div>
</div>
<div className="col-md-12 col-sm-12 col-xs-12 prdHeading">

<h3>Drugs acting on Respiratory System </h3>
<hr></hr>

</div>
    <div className="row text-center">
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="about-move">
          <div className="services-details">
            <div className="single-services">
              <img src={product17}></img>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product18}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product19}></img>
    
            </div>
          </div>
        </div>
      </div>
      
</div>
<div className="col-md-12 col-sm-12 col-xs-12 prdHeading">

<h3>Drugs acting on GIT </h3>
<hr></hr>

</div>
    <div className="row text-center">
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="about-move">
          <div className="services-details">
            <div className="single-services">
              <img src={product20}></img>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product21}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product22}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product23}></img>
    
            </div>
          </div>
        </div>
      </div>
     
</div>
<div className="col-md-12 col-sm-12 col-xs-12 prdHeading">

<h3>Anti Spasmodic Range of Products</h3>
<hr></hr>

</div>
    <div className="row text-center">
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="about-move">
          <div className="services-details">
            <div className="single-services">
              <img src={product24}></img>
            </div>
          </div>
        </div>
      </div>
      
</div>
<div className="col-md-12 col-sm-12 col-xs-12 prdHeading">

<h3>NSAIDS Range of Products</h3>
<hr></hr>
</div>
    <div className="row text-center">
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="about-move">
          <div className="services-details">
            <div className="single-services">
              <img src={product25}></img>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product26}></img>
    
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className=" about-move">
          <div className="services-details">
            <div className="single-services">
            <img src={product27}></img>
    
            </div>
          </div>
        </div>
      </div>
      
</div>
<div className="col-md-12 col-sm-12 col-xs-12 prdHeading">

<h3>Harmonal Range of Products</h3>
<hr></hr>

</div>
    <div className="row text-center">
      <div className="col-md-4 col-sm-4 col-xs-12">
        <div className="about-move">
          <div className="services-details">
            <div className="single-services">
              <img src={product28}></img>
            </div>
          </div>
        </div>
      </div>
     
   
</div>


  </div>
</div>
<div id="team" className="our-team-area area-padding">
  <div className="container">
    <div className="row">
      <div className="col-md-12 col-sm-12 col-xs-12">
        <div className="section-headline text-center">
          <h2>Addressing Annual Conference</h2>

        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-3 col-sm-3 col-xs-12">
        <div className="single-team-member">
          <div className="team-img">
            <a href="#">
              <img src={conference1} alt=""/>
            </a>
            <div className="team-social-icon text-center">
              <ul>
                <li>
                  <a href="#">
                    <i className="bi bi-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="bi bi-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="bi bi-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="team-content text-center">
            <h4></h4>
            <p></p>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-3 col-xs-12">
        <div className="single-team-member">
          <div className="team-img">
            <a href="#">
              <img src={conference2} alt=""/>
            </a>
            <a href="#">
              <img src={conference6} alt=""/>
            </a>
            <div className="team-social-icon text-center">
              <ul>
                <li>
                  <a href="#">
                    <i className="bi bi-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="bi bi-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="bi bi-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="team-content text-center">
            <h4></h4>
            <p></p>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-3 col-xs-12">
        <div className="single-team-member">
          <div className="team-img">
            <a href="#">
              <img src={conference3} alt=""/>
            </a>
            <div className="team-social-icon text-center">
              <ul>
                <li>
                  <a href="#">
                    <i className="bi bi-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="bi bi-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="bi bi-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="team-content text-center">
            <h4></h4>
            <p></p>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-3 col-xs-12">
        <div className="single-team-member">
          <div className="team-img">
            <a href="#">
              <img src={conference4} alt=""/>
            </a>
            <div className="team-social-icon text-center">
              <ul>
                <li>
                  <a href="#">
                    <i className="bi bi-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="bi bi-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="bi bi-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="team-content text-center">
                </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="testimonials" className="testimonials">
  <div className="container">

    <div className="testimonials-slider swiper">
      <div className="swiper-wrapper">

        <div className="swiper-slide">
          <div className="testimonial-item">
            <img src={rnd}  alt=""/>
            
          </div>
        </div>
        
      </div>
      <div className="swiper-pagination"></div>
    </div>

  </div>
</div>

{/* <div id="blog" className="blog-area">
  <div className="blog-inner area-padding">
    <div className="blog-overly"></div>
    <div className="container ">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="section-headline text-center">
            <h2>Latest News</h2>
          </div>
        </div>
      </div>
      <div className="row">
       <div className="col-md-4 col-sm-4 col-xs-12">
          <div className="single-blog">
            <div className="single-blog-img">
              <a href="blog.html">
                <img src="assets/img/blog/1.jpg" alt=""/>
              </a>
            </div>
            <div className="blog-meta">
              <span className="comments-type">
                <i className="fa fa-comment-o"></i>
                <a href="#">13 comments</a>
              </span>
              <span className="date-type">
                <i className="fa fa-calendar"></i>2016-03-05 / 09:10:16
              </span>
            </div>
            <div className="blog-text">
              <h4>
                <a href="blog.html">Assumenda repud eum veniam</a>
              </h4>
              <p>
                Lorem ipsum dolor sit amet conse adipis elit Assumenda repud eum veniam optio modi sit explicabo nisi magnam quibusdam.sit amet conse adipis elit Assumenda repud eum veniam optio modi sit explicabo nisi magnam quibusdam.
              </p>
            </div>
            <span>
              <a href="blog.html" className="ready-btn">Read more</a>
            </span>
          </div>
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12">
          <div className="single-blog">
            <div className="single-blog-img">
              <a href="blog.html">
                <img src="assets/img/blog/2.jpg" alt=""/>
              </a>
            </div>
            <div className="blog-meta">
              <span className="comments-type">
                <i className="fa fa-comment-o"></i>
                <a href="#">130 comments</a>
              </span>
              <span className="date-type">
                <i className="fa fa-calendar"></i>2016-03-05 / 09:10:16
              </span>
            </div>
            <div className="blog-text">
              <h4>
                <a href="blog.html">Explicabo magnam quibusdam.</a>
              </h4>
              <p>
                Lorem ipsum dolor sit amet conse adipis elit Assumenda repud eum veniam optio modi sit explicabo nisi magnam quibusdam.sit amet conse adipis elit Assumenda repud eum veniam optio modi sit explicabo nisi magnam quibusdam.
              </p>
            </div>
            <span>
              <a href="blog.html" className="ready-btn">Read more</a>
            </span>
          </div>
        </div>
       <div className="col-md-4 col-sm-4 col-xs-12">
          <div className="single-blog">
            <div className="single-blog-img">
              <a href="blog.html">
                <img src="assets/img/blog/3.jpg" alt=""/>
              </a>
            </div>
            <div className="blog-meta">
              <span className="comments-type">
                <i className="fa fa-comment-o"></i>
                <a href="#">10 comments</a>
              </span>
              <span className="date-type">
                <i className="fa fa-calendar"></i>2016-03-05 / 09:10:16
              </span>
            </div>
            <div className="blog-text">
              <h4>
                <a href="blog.html">Lorem ipsum dolor sit amet</a>
              </h4>
              <p>
                Lorem ipsum dolor sit amet conse adipis elit Assumenda repud eum veniam optio modi sit explicabo nisi magnam quibusdam.sit amet conse adipis elit Assumenda repud eum veniam optio modi sit explicabo nisi magnam quibusdam.
              </p>
            </div>
            <span>
              <a href="blog.html" className="ready-btn">Read more</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}
<div id="contact" className="contact-area">
  <div className="contact-inner area-padding">
    <div className="contact-overly"></div>
    <div className="container ">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="section-headline text-center">
            <h2>Contact us</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="contact-icon text-center">
            <div className="single-icon">
              <i className="bi bi-phone"></i>
              <p>
                Call: +91 9980630006<br/>
                <span>Monday-Friday (10:30am-7:30pm)</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="contact-icon text-center">
            <div className="single-icon">
              <i className="bi bi-envelope"></i>
              <p>
                Email: bioplasmidhealthcare@gmail.com<br/>
                <span>Web: http://bioplasmidhealthcare.com</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="contact-icon text-center">
            <div className="single-icon">
              <i className="bi bi-geo-alt"></i>
              <p>
                Location: 
               
              </p>
              <p>
                Admn. Office : Bioplasmid Healthcare, No-2-907/78/3/52, M G Road, Beside GESCOM office, Kalaburagi, Karnataka 585105<br/>
              </p>
              <p>
                Marketing. Office : No. 605/2/604, 14th Cross, 5th Main Road, M.C Layout, Vijaynagar, Bangalore, Karnataka 560040<br/>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">

        <div className="col-md-6">
          <img src={officePhoto} alt='Office Address'/>
        </div>
      
        <div className="col-md-6">
          <div className="form contact-form">
            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="form-group">
                <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
              </div>
              <div className="form-group mt-3">
                <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</main>

    </div>
  );
}




export default App;