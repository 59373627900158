import React from "react";
import { BrowserRouter,  Routes, Route } from "react-router-dom";
import logo from '../images/bplogonew.png'; 
function NavbarCorparate() {
  return (
    
<nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">
      <li className="nav-item active">
        <a className="nav-link" href="#">Corporate <span className="sr-only">(current)</span></a>
     </li>
      <li className="nav-item">
        <a className="nav-link" href="#">People</a>
      </li>
      <li className="nav-item dropdown">
        <a className="nav-link " href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {"Manufacturing & Quality"}
        </a>
             </li>
      <li className="nav-item">
        <a className="nav-link" href="#">{"R&D"}</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">Products</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">NewsRoom</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">Employees</a>
      </li>
    
    </ul>
      
 
  </div>
</nav>
  );
}



export default NavbarCorparate;