import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from '../images/bplogonew.png';
import image1 from '../images/Director_Bioplasmid.jpg'
import image2 from '../images/Team2BP.jpg'
import image3 from '../images/Akumes.jpg'

function Carousel() {
  return (
    <div id="carousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="d-flex h-100 d-inline-block min-vh-100 min-vw-100">
            <img src={image1} className="img-fluid" alt="..." />
          </div>
        </div>
        <div className="carousel-item">
          
          <div className="d-flex h-100 d-inline-block min-vh-100 min-vw-100">
            <img src={image3} className="img-fluid" alt="..." />
          </div>
        </div>
        <div className="carousel-item">
          <div className="d-flex h-100 d-inline-block min-vh-100 min-vw-100">
            <img src={image2} className="img-fluid " alt="..." />
          </div>
        </div>
      </div>


    </div>
  );
}



export default Carousel;
